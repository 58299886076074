import React, { useEffect, useState } from "react";
import { users } from "../../requests";
import { Button, Modal, Card } from "antd";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { saveAs } from 'file-saver';
import { InputText } from 'primereact/inputtext';
import { Button as Pribtn } from 'primereact/button';
import "primereact/resources/themes/lara-light-cyan/theme.css";

const Users = () => {
  const [usersData, setUsersData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: 'contains' },
    bookingId: { value: null, matchMode: 'startsWith' },
    // Add more filters as needed
  });

  const fetchUsersData = async () => {
    try {
      const token = localStorage.getItem("token");
      const resp = await users(token);

      const formattedUsers = resp.data.users.map((user, index) => {
        const utcTimestamp = user.createdAt;
        const dateInUTC = new Date(utcTimestamp);
        const options = {
          timeZone: 'Asia/Kolkata',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        };
        const dateInIST = dateInUTC.toLocaleDateString('en-US', options);
      
        return {
          ...user,
          serialNumber: index + 1,
          createdAt: dateInIST,
        };
      });
      
      

      setUsersData(formattedUsers);
    } catch (error) {
      console.log(error);
    }
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={filters.global.value}
            onChange={(e) =>
              setFilters({ ...filters, global: { value: e.target.value, matchMode: 'contains' } })
            }
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  useEffect(() => {
    fetchUsersData();
  }, []);

  const columns = [
    { field: 'displayName', header: 'Name' },
    { field: 'phoneNumber', header: 'Phone Number' },
    { field: 'email', header: 'Email' },
    { field: 'createdAt', header: 'Created At' },
    {
      body: (rowData) => (
        <Button onClick={() => showTickets(rowData)}>Show Tickets</Button>
      ),
    },
  ];

  const showTickets = (user) => {
    setSelectedUser(user);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const exportPdf = () => {
    import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then(() => {
        const doc = new jsPDF.default(0, 0);
  
        const exportColumns = [
          { title: 'Name', dataKey: 'displayName' },
          { title: 'Phone Number', dataKey: 'phoneNumber' },
          { title: 'Email', dataKey: 'email' },
          { title: 'Created At', dataKey: 'createdAt' },
        ];
  
        const dataForPdf = usersData.map((user) => ({ ...user }));
  
        doc.autoTable(exportColumns, dataForPdf);
        doc.save('users.pdf');
      });
    });
  };

  return (
    <div>
      <h1>Users</h1>
      <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
        <Pribtn type="button" label="Export PDF" icon="pi pi-file-pdf" rounded onClick={exportPdf} />
        {/* <Button label="Export Excel" icon="pi pi-file-excel" rounded onClick={exportExcel} /> */}
      </div>
      <DataTable
        value={usersData}
        paginator
        rows={10}
        dataKey="id"
        filters={filters}
        filterDisplay="row"
        header={renderHeader()}
        emptyMessage="No users found."
      >
        <Column field="serialNumber" header="Sr. No." />
        <Column field="displayName" header="Name" filterPlaceholder="Search by Name" />
        <Column field="phoneNumber" header="Phone Number" filterPlaceholder="Search by Phone Number" />
        <Column field="email" header="Email" filterPlaceholder="Search by Email" />
        <Column field="createdAt" header="Created At" filterPlaceholder="Search by Created At" />
        <Column
          body={(rowData) => (
            <Pribtn label="Show Tickets" onClick={() => showTickets(rowData)} />
          )}
        />
      </DataTable>
      <Modal
        title="Booked Tickets"
        visible={modalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        {selectedUser &&
          selectedUser.bookedTickets.map((ticket, index) => (
            <Card
              key={index}
              style={{ marginBottom: 10, backgroundColor: "#1e90ff" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <p style={{ color: "white" }}>Event Name: {ticket.eventName}</p>
                  <p style={{ color: "white" }}>City: {ticket.city}</p>
                  <p style={{ color: "white" }}>Time: {ticket.time}</p>
                  <p style={{ color: "white" }}>Venue: {ticket.venue}</p>
                  <p style={{ color: "white" }}>
                    Event Date: {new Date(ticket.date).toLocaleDateString()}
                  </p>
                  <p style={{ color: "white" }}>Booking ID: {ticket.bookingId}</p>
                </div>
                <img src={ticket.qrCode} alt="QR Code" />
              </div>
            </Card>
          ))}
      </Modal>
    </div>
  );
};

export default Users;
