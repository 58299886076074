import React from "react";
import { Card, Button,notification } from "antd";

const CandidateCard = ({ candidate }) => {
  const { name, qualification, address, phoneNumber, email, resume } =
    candidate;

    const handleDownloadResume = () => {
        const anchor = document.createElement("a");
        anchor.href = resume;
    
        // Extracting the original filename from the URL
        const originalFileName = resume.split("/").pop();
    
        // Use the candidate's name and original filename for the downloaded file
        anchor.download = `${name.replace(/\s/g, "_")}_${originalFileName}`;
    
        // Trigger a click on the anchor element to initiate the download
        document.body.appendChild(anchor);
        anchor.click();
    
        // Show a notification after the download
        notification.success({
            message: 'Resume Downloaded',
            description: `Resume for ${name} has been downloaded successfully.`,
        });
    
        // Wait for the download prompt to appear and then remove the element
        setTimeout(() => {
            document.body.removeChild(anchor);
        }, 1000);
    };
    

  return (
    <Card title={name} style={{ width: 300, marginBottom: 16 }}>
      <p>
        <strong>Qualification:</strong> {qualification}
      </p>
      <p>
        <strong>Address:</strong> {address}
      </p>
      <p>
        <strong>Phone Number:</strong> {phoneNumber}
      </p>
      <p>
        <strong>Email:</strong> {email}
      </p>

      {/* Add Download Resume button */}
      <Button
        type="primary"
        onClick={handleDownloadResume}
        style={{ marginTop: 16 }}
      >
        Download Resume
      </Button>
    </Card>
  );
};

export default CandidateCard;
