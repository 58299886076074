import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { getadmins } from "../../requests";

const ListAdmins = () => {

    const [admins,setadmins]=useState([])

    const list=async()=>{
        try{
            const resp=await getadmins()
            console.log(resp.data)
            setadmins(resp.data)

        }catch(err){
            console.log(err)
        }
    }

    useEffect(()=>{
        list()
    },[])
  
  const columns = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Organizer UID",
      dataIndex: "organiserUID",
      key: "organiserUID",
    },
    {
      title: "Organizer Name",
      dataIndex: "organizerName",
      key: "organizerName",
    },
  ];

  return (
    <div>
      <h2 style={{color:'black'}}>List of Admins</h2>
      <Table dataSource={admins} columns={columns} rowKey="_id" />
    </div>
  );
};

export default ListAdmins;
