import React from "react";
import { Form, Input, Select, Button,notification } from "antd";
import { addOrganizer } from "../../requests";

const { Option } = Select;

const AddAdmin = () => {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("Token not found in local storage");
        return;
      }

      const resp = await addOrganizer(values, token);
      console.log(resp);
      form.resetFields();
      notification.success({
        message: "Success",
        description: resp.message || "Operation completed successfully",
      });
    } catch (err) {
        notification.error({
            message: "Error",
            description: "An error occurred. Please try again later.",
          });
    }
  };

//   const handleRoleChange = (value) => {
//     if (value === "superadmin") {
//       form.setFieldsValue({ organizerName: undefined });
//     }
//   };

  return (
    <Form
      form={form}
      name="addAdminForm"
      onFinish={onFinish}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 14 }}
    >
      <Form.Item
        label="Username"
        name="username"
        rules={[{ required: true, message: "Please input the username!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: "Please input the password!" }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        label="Role"
        name="role"
        rules={[{ required: true, message: "Please select the role!" }]}
      >
        <Select >
          {/* <Option value="superadmin">Superadmin</Option> */}
          <Option value="organizer">Organizer</Option>
        </Select>
      </Form.Item>

      {/* Conditionally render the Organizer Name field based on the selected role */}

      <Form.Item
        label="Organizer Name"
        name="organizerName"
        rules={[
          { required: true, message: "Please input the organizer name!" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 6, span: 14 }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddAdmin;
