import React from "react";
import logo from "../../src/assets/images/logo.svg";
import { Layout, Menu, Button } from "antd";
import {
  FileTextOutlined,
  UserOutlined,
  LogoutOutlined,
  DashboardOutlined,
  SettingOutlined,
  AppstoreOutlined,
  ProjectOutlined,
  NotificationOutlined ,
  TagOutlined ,
  SelectOutlined ,
  PlaySquareOutlined 
} from "@ant-design/icons";
import { Link, Outlet, useNavigate } from "react-router-dom";
import styles from "./basic.module.css";
import { useState, useEffect } from "react";

const { Sider, Content, Header,Footer } = Layout;

const BasicLayout = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState("dashboard");
  const [isOrganizer, setIsOrganizer] = useState(false);
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("token");
    sessionStorage.removeItem("organizerUID");
    localStorage.removeItem("organizerName");

    navigate("/");

    console.log("Logged out");
  };
  const menuItems = {
    dashboard: "Dashboard",
    tickets: "All Tickets",
    addTickets: "Add Tickets",
    bookings: "Bookings",
    users: "Users",
    password: "Reset Password",
    addcity: "Add City",
  };
  const getHeaderTitle = () => {
    return menuItems[selectedMenuItem] || "";
  };

  useEffect(() => {
    // Check if organizer information is present in local storage and is not null
    const organizerUID = sessionStorage.getItem("organizerUID");

    if (organizerUID !== null) {
      setIsOrganizer(true);
    }
  }, []);
  
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider width={200} theme="dark">
        {/* <h1 style={{ textAlign: "center", color: "white" }}>Wildcard</h1> */}
        <div
          style={{ display: "flex", justifyContent: "center", padding: "10px" }}
        >
          <img src={logo} alt="logo" style={{ width: "160px" }} />
        </div>
        <Menu
          mode="vertical"
          theme="dark"
          defaultSelectedKeys={["dashboard"]}
          onSelect={({ key }) => setSelectedMenuItem(key)}
        >
          <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
            <Link to="/app">Dashboard</Link>
          </Menu.Item>
          <Menu.SubMenu
            key="ticketsSubMenu"
            icon={<PlaySquareOutlined />}
            title="Tickets"
          >
            <Menu.Item key="tickets">
              <Link to="/app/tickets">Tickets</Link>
            </Menu.Item>
            <Menu.Item key="addTickets">
              <Link to="/app/addticket">Add Tickets</Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item key="bookings" icon={<ProjectOutlined />}>
            <Link to="/app/bookings">Bookings</Link>
          </Menu.Item>

          {isOrganizer ? null : (
            <>
              <Menu.Item key="users" icon={<UserOutlined />}>
                <Link to="/app/users">Users</Link>
              </Menu.Item>

              <Menu.Item
                key="queries"
                icon={<NotificationOutlined />}
                title="Queries"
              >
                <Link to="/app/queries">Queries</Link>
              </Menu.Item>

              <Menu.Item
                key="listyourevents"
                icon={<TagOutlined />}
                title="List your events"
              >
                <Link to="/app/listyourevents">List Your events</Link>
              </Menu.Item>
              <Menu.Item
            key="career"
            icon={<FileTextOutlined />}
            title="Career"
          >
            <Link to="/app/career">Career</Link>
          </Menu.Item>
              <Menu.SubMenu
                key="master"
                icon={<SelectOutlined />}
                title="Master"
              >
                <Menu.Item key="addcity">
                  <Link to="/app/addcity">Add City</Link>
                </Menu.Item>

                <Menu.Item key="partner">
                  <Link to="/app/partners">Add Partners</Link>
                </Menu.Item>

                <Menu.Item key="addcategory">
                  <Link to="/app/addcategory">Add Category</Link>
                </Menu.Item>
              </Menu.SubMenu>

              <Menu.SubMenu
                key="access"
                title="Manage Admins"
                icon={<UserOutlined />}
              >
                <Menu.Item key="addcategory">
                  <Link to="/app/addadmin">Add Admin</Link>
                </Menu.Item>
                <Menu.Item key="alladmins">
                  <Link to="/app/listadmins">All Admins</Link>
                </Menu.Item>
             
              </Menu.SubMenu>
            </>
          )}
        
          <Menu.SubMenu
            key="settings"
            icon={<SettingOutlined />}
            title="Settings"
          >
            <Menu.Item key="password">
              <Link to="/app/resetpass">Reset Password</Link>
            </Menu.Item>
          </Menu.SubMenu>

          {/* <Menu.Item
            key="listyourevents"
            icon={<AppstoreOutlined />}
            title="List your events"
          >
            <Link to="/app/testing">test seats</Link>
          </Menu.Item> */}

         
        </Menu>
      </Sider>
      <Layout>
        <Header>
          <h2>{getHeaderTitle()}</h2>
          <Button
            type="primary"
            onClick={handleLogout}
            icon={<LogoutOutlined />}
            danger
          >
            Logout
          </Button>
          {/* <Link to="/app/tickets">Manage Tickets</Link>
          <Link to="/app/users">Users</Link>
          <span onClick={handleLogout}>Logout</span> */}
        </Header>
        <Content
          style={{
            padding: "20px",
            overflowY: "auto",
            height: "calc(100vh - 64px - 20px)",
          }}
        >
          <Outlet />
        
        </Content>
        
      </Layout>
      <Footer className={styles.footer} style={{backgroundColor: "#001529"}}>
        Powered By CreWork
      </Footer>
    </Layout>
  );
};

export default BasicLayout;
