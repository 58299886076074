import React, { useState } from 'react';
import { S3 } from 'aws-sdk';
import { Button } from "primereact/button";
import './awss3.css';
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';

const AWSS3Uploader = ({ onUploadComplete }) => {
  const [file, setFile] = useState(null);
  const [uploadMessage, setUploadMessage] = useState(null);
  const [uploading, setUploading] = useState(false);

  const AWS_ACCESS_KEY_ID = "AKIA3DVBXXI7ETI7QZU7";
  const AWS_SECRET_ACCESS_KEY = "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";
  const AWS_DEFAULT_REGION = "ap-south-1";

  // Set AWS credentials for the AWS SDK
  const awsCredentials = {
    accessKeyId: AWS_ACCESS_KEY_ID,
    secretAccessKey: AWS_SECRET_ACCESS_KEY,
    region: AWS_DEFAULT_REGION,
  };

  const uploadToS3 = async (file) => {
    const s3 = new S3(awsCredentials);

    const params = {
      Bucket: "wildcardimages",
      Key: `${file.name}`,
      Body: file,
      ContentType: file.type,
      ContentDisposition: "inline",
    };

    try {
      setUploading(true);
      // Upload the file to S3
      const result = await s3.upload(params).promise();

      // Generate the object URL with the correct region
      const objectUrl = `https://${params.Bucket}.s3.${awsCredentials.region}.amazonaws.com/${params.Key}`;

      // Call the callback function with the uploaded URL
      onUploadComplete(objectUrl);

      setUploadMessage({ severity: 'success', summary: 'Upload Successful', detail: 'Image uploaded successfully!' });
    } catch (error) {
      console.error(`Error uploading file ${file.name} to S3:`, error);
      // Or handle the error as needed
    }finally {
      setUploading(false);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = () => {
    if (file) {
      uploadToS3(file);
    }
  };

  return (
    <div className='uploadAWS'>
      <input type="file" onChange={handleFileChange} />
      <Button
          label="Upload"
          icon="pi pi-upload"
          onClick={handleUpload}
          className="p-button-success" 
          disabled={!file}
        />
        <div className='loading'>
          {uploading && <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth='4' fill='#EEEEEE'  />}
        {uploadMessage && (
        <Message
          severity={uploadMessage.severity}
          summary={uploadMessage.summary}
          detail={uploadMessage.detail}
          life={5000} 
          onClose={() => setUploadMessage(null)} 
        />
      )}
      </div>
    </div>
  );
};

export default AWSS3Uploader;
