import React, { useEffect, useState } from "react";
import { listevents } from "../../requests";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { saveAs } from "file-saver";

const Listyourevents = () => {
  const [ListEvents, setListEvents] = useState([]);

  const fetchData = async () => {
    try {
      const resp = await listevents();
      setListEvents(resp.data.events);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    { field: "yourName", header: "Name" },
    { field: "companyName", header: "Company Name" },
    { field: "email", header: "Email" },
    { field: "formattedDate", header: "Event Date" },
    { field: "eventDetails", header: "Event Details" },
    { field: "phone", header: "Phone" },
    { field: "venueLocation", header: "Venue Location" },
  ];

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);

        const exportColumns = columns.map((column) => ({
          title: column.header,
          dataKey: column.field,
        }));

        const dataForPdf = ListEvents.map((event) => ({ ...event }));

        doc.autoTable(exportColumns, dataForPdf);
        doc.save("events.pdf");
      });
    });
  };

  return (
    <div>
      {/* <h2>List of Events</h2> */}
      <div style={{ marginBottom: "10px" }}>
        <Button
          type="button"
          label="Export PDF"
          icon="pi pi-file-pdf"
          rounded
          onClick={exportPdf}
        />
      </div>
      <DataTable
        value={ListEvents}
        paginator
        rows={10}
        dataKey="id"
        header="List of Events"
        emptyMessage="No events found."
      >
        {columns.map((column, index) => (
          <Column
            key={index}
            field={column.field}
            header={column.header}
            body={column.body}
          />
        ))}
      </DataTable>
    </div>
  );
};

export default Listyourevents;
