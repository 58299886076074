import { useEffect, useState } from "react";
import { getTickets,deleteTicket,ticketFilter } from "../../requests";
import { Card,Row,Col, Button ,Tooltip,Spin} from "antd";
import {  LoadingOutlined }  from '@ant-design/icons';
import TicketModal from "../../components/ticketModal";

const ManageTickets = () => {

const[Tickets,setTickets]=useState([])
const [loading, setLoading] = useState(false);
const [selectedTicket, setSelectedTicket] = useState(null);
const [modalVisible, setModalVisible] = useState(false);

const getData = async () => {
  try {
    setLoading(true);
    const resp = await getTickets();
    setTickets(resp.data.data.tickets);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

const getfiltered = async () => {
  try {
    setLoading(true);
    // Retrieve organizerName from localStorage
    const organizerName = localStorage.getItem("organizerName");
    const token = localStorage.getItem("token"); 

    if (organizerName) {
      // Run the filtering logic using the organizerName
      const resp = await ticketFilter({ organizerName :organizerName},token);
      setTickets(resp.data.filteredTickets);
      console.log(resp.data);
    } else {
      console.log("No organizerName found in localStorage.");
    }
  } catch (err) {
    console.log(err);
  }finally{
    setLoading(false)
  }
};

useEffect(() => {
  // Check if organizerName is in localStorage
  const organizerName = localStorage.getItem("organizerName");

  if (organizerName) {
    // If organizerName exists, run getfiltered
    getfiltered();
  } else {
    // If organizerName doesn't exist, run getData
    getData();
  }
}, []);

  const handledeleteTicket=async(id)=>{
try{
  setLoading(true)
const token=localStorage.getItem('token')
const resp=await deleteTicket(id,token)
console.log(resp)
}
catch(err){
  console.log(err)
}
finally {
  setLoading(false); 
  getData()
}
  }

 

  console.log(Tickets)


  const handleEdit = () => {
    setModalVisible(true);
    
  };

  const closeModal = () => {
    setModalVisible(false);

    setSelectedTicket(null)
    getData()
  };
  console.log(Tickets)

  return (
    <div>
      
     <Row gutter={16}>
        {Tickets.map((ticket) => (
          <Col key={ticket._id} span={8}>
            <Card
              title={ticket.name}
              extra={ticket.city}
              style={{ margin: '16px 0' }}
            >
              <p>Artist: {ticket.artistName}</p>
              <p>Date: {new Date(ticket.eventDate).toDateString()}</p>
              <p>Time: {ticket.time}</p>
              <p>Category: {ticket.eventCategory}</p>
              <p>Venue: {ticket.venue}</p>
              <p>Price: ₹{ticket.price}</p>
              <p>Seats: {ticket.numberOfSeats}</p>
              <p>Status: {ticket.status}</p>
              <Tooltip title={ticket.description}>
                <p
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  Description: {ticket.description}
                </p>
              </Tooltip>
              
              <div style={{display:'flex', gap:'10px'}}>
              <Button type="primary" onClick={() => { setSelectedTicket(ticket); handleEdit(); }}>
                  Edit
                </Button>
                <Button type="primary" danger 
                onClick={()=>handledeleteTicket(ticket._id)}>Delete</Button>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
      
      <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} spinning={loading} fullscreen/>


      <TicketModal visible={modalVisible} onCancel={closeModal} ticket={selectedTicket} />
    </div>
  );
};

export default ManageTickets;
