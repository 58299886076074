import React from 'react';
import { BrowserRouter as Router, Route, Switch,Routes } from 'react-router-dom';
import Rout from './routes/routes'

import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
        
function App() {
  return (
    <PrimeReactProvider>
    <Rout/>
    </PrimeReactProvider>
 
  )}

export default App;
