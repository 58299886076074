import { getcategory, addcategory, categorydelete } from "../../requests";
import React, { useEffect, useState } from "react";
import {
  Table,
  Checkbox,
  Button,
  Popconfirm,
  message,
  Modal,
  Input,
  notification,
} from "antd";

const AddCategory = () => {
  const [category, setCategory] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newCategory, setnewcategory] = useState("");

  const columns = [
    {
      title: "Category",
      dataIndex: "name",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <Popconfirm
          title="Sure to delete?"
          onConfirm={() => handleDeleteCategory(record._id)}
        >
          <Button type="primary" danger>
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];

  const getcategoryall = async () => {
    try {
      const resp = await getcategory();
      setCategory(resp.data);
      console.log(resp.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getcategoryall();
  }, []);

  const handleModalOk = async () => {
    try {
      const token = localStorage.getItem('token');

      const resp = await addcategory({ name: newCategory},token);
      console.log(resp);
      getcategoryall();
      setIsModalVisible(false);
      setnewcategory("");
      message.success(" added successfully!");
      console.log(newCategory);
    } catch (err) {
      console.log(err);
      message.error('Something went wrong')
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setnewcategory("");
  };

  const handleDeleteCategory = async (key) => {
    try {
      const token = localStorage.getItem('token');

    const resp = await categorydelete(key, token);

      // Display success notification
      notification.success({
        message: "Category Deleted",
        description: "The category has been successfully deleted.",
      });

      // Refresh the list of categories (assuming this function is available)
      getcategoryall();

      console.log(resp);
      console.log(key);
    } catch (err) {
      // Display error notification
      notification.error({
        message: "Error",
        description: "An error occurred while deleting the category.",
      });

      console.log(err);
    }
  };

  const handleAddCity = () => {
    setIsModalVisible(true);
  };

  const capitalizeFirstLetter = (input) => {
    return input.charAt(0).toUpperCase() + input.slice(1);
  };

  return (
    <div>
      <Button
        type="primary"
        onClick={handleAddCity}
        style={{ marginTop: "16px" }}
      >
        Add Category
      </Button>
      <br></br>
      <Table dataSource={category} columns={columns} />

      <Modal
        title="Add Category"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Input
          placeholder="Enter category"
          value={newCategory}
          onChange={(e) =>
            setnewcategory(capitalizeFirstLetter(e.target.value))
          }
        />
      </Modal>
    </div>
  );
};

export default AddCategory;
