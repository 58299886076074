import React, { useEffect, useState } from "react";
import {
  Table,
  Checkbox,
  Button,
  Popconfirm,
  message,
  Modal,
  Input,notification
} from "antd";
import { getcities, addCity,citydelete } from "../../requests";

const AddCity = () => {
  const [cities, setCities] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newCityName, setNewCityName] = useState("");

  const citiesGet = async () => {
    try {
      const resp = await getcities();
      setCities(resp.data);
      console.log(resp.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    citiesGet();
  }, []);

  const handleAddCity = () => {
    setIsModalVisible(true);
  };

  const columns = [
    {
      title: "City Name",
      dataIndex: "name",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <Popconfirm
          title="Sure to delete?"
          onConfirm={() => handleDeleteCity(record._id)}
        >
          <Button type="primary" danger>
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];

  const handleDeleteCity = async (key) => {
    try {
      const token = localStorage.getItem('token');

      const resp = await citydelete(key, token);
  
      // Display success notification
      notification.success({
        message: 'City Deleted',
        description: 'The city has been successfully deleted.',
      });
  
      // Refresh the list of cities (assuming this function is available)
      citiesGet();
  
      console.log(resp);
      console.log(key);
    } catch (err) {
      // Display error notification
      notification.error({
        message: 'Error',
        description: 'An error occurred while deleting the city.',
      });
  
      console.log(err);
    }
  };

  const handleModalOk = async () => {
    try{
      const token = localStorage.getItem('token');

      const resp = await addCity({ name: newCityName },token);
    console.log(resp);
    citiesGet();
    setIsModalVisible(false);
    setNewCityName("");
    message.success("City added successfully!");
    console.log(newCityName);}
    catch(err){
      console.log(err)
      message.error('Something went wrong')
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setNewCityName("");
  };

  
  return (
    <div>
      <Button
        type="primary"
        onClick={handleAddCity}
        style={{ marginTop: "16px" }}
      >
        Add City
      </Button>

      <Table dataSource={cities} columns={columns} />
      <Modal
        title="Add City"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Input
          placeholder="Enter city name"
          value={newCityName}
          onChange={(e) => setNewCityName(e.target.value)}
        />
      </Modal>
    </div>
  );
};
export default AddCity;
