import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primeicons/primeicons.css";
import {notification} from 'antd'
import AWSS3Uploader from "../../components/s3uploader";
import { partnersAdd,partners,partnerDelete } from "../../requests";
const Partners = () => {
  const [Partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [displayDeleteDialog, setDisplayDeleteDialog] = useState(false);
  const [displayAddPartnerDialog, setDisplayAddPartnerDialog] = useState(false);
  const [newPartner, setNewPartner] = useState({
    partnerImage: "",
    partnerName: "",
  });

  const fetchPartners = async() => {
    try{
      const resp=await partners()
      // console.log(resp)
    
        setPartners(resp.data);

    }catch(err){
      console.log(err)
    }
 
  };

  useEffect(() => {
    fetchPartners();
  }, []);

  const addPartner = () => {
    setDisplayAddPartnerDialog(true);
  };


  const saveNewPartner = async() => {
  try{
    const token=localStorage.getItem('token')
    const resp=await partnersAdd(newPartner,token)
    notification.success({
      message: 'Success',
      description: 'Partner added successfully!',
    });


    setNewPartner({
      partnerImage: '',
      partnerName: '',
    });
   
    setDisplayAddPartnerDialog(false);
  }catch(err){
    notification.error({
      message: err.response ? err.response.data.message : 'Error',
            description: 'Error adding partner. Please try again.',
    });
    console.log(err)

  }finally{
    fetchPartners()
  }}

  

  const cancelAddPartner = () => {
    setNewPartner({ partnerImage: "", partnerName: "" });
    setDisplayAddPartnerDialog(false);
  };
  const columns = [
    { field: "partnerImage", header: "Partner Image" },
    { field: "partnerName", header: "Partner Name" },
    { field: "actions", header: "Actions" },
  ];

  const confirmDelete = (partner) => {
    setSelectedPartner(partner);
    setDisplayDeleteDialog(true);
  };

  const deleteConfirmed = async () => {
    try {
      const token=localStorage.getItem('token')
      console.log('Deleting partner with id:', selectedPartner._id);
      const resp=await partnerDelete(selectedPartner._id,token)
      
      setPartners((prevPartners) =>
        prevPartners.filter((partner) => partner.id !== selectedPartner.id)
      );
      setDisplayDeleteDialog(false);
    } catch (err) {
      console.log(err);
    }finally{
      fetchPartners()
    }
  };

  const deleteCancel = () => {
    setDisplayDeleteDialog(false);
  };

  return (
    <div>
      <div style={{ marginBottom: "10px" }}>
        {/* Add Partner Button - Implement the logic for adding partners */}
        <Button
          type="button"
          label="Add Partner"
          icon="pi pi-plus"
          rounded
          onClick={addPartner}
        />
      </div>
      <DataTable
        value={Partners}
        paginator
        rows={10}
        dataKey="id"
        header="Partners"
        emptyMessage="No Partners found."
      >
        {columns.map((column, index) => (
          <Column
            key={index}
            field={column.field}
            header={column.header}
            body={
              column.field === "actions"
                ? (rowData) => (
                    <div>
                      <Button
                        icon="pi pi-trash"
                        className="p-button-danger"
                        onClick={() => confirmDelete(rowData)}
                      />
                    </div>
                  )
                : column.field === "partnerImage"
                ? (rowData) => <img src={rowData.partnerImage} alt="Partner" height={60}/>
                : column.field === "partnerName"
                ? (rowData) => <span>{rowData.partnerName}</span>
                : column.body
            }
          />
        ))}
      </DataTable>

      {/* Delete Confirmation Dialog */}
      <Dialog
        header="Confirmation"
        visible={displayDeleteDialog}
        style={{ width: "50vw" }}
        modal
        onHide={deleteCancel}
        footer={
          <div>
            <Button label="Cancel" icon="pi pi-times" onClick={deleteCancel} />
            <Button
              label="Delete"
              icon="pi pi-check"
              onClick={deleteConfirmed}
              className="p-button-danger"
            />
          </div>
        }
      >
        <div>
          <p>Are you sure you want to delete this partner?</p>
        </div>
      </Dialog>


      <Dialog
        header="Add Partner"
        visible={displayAddPartnerDialog}
        style={{ width: "50vw" }}
        modal
        onHide={cancelAddPartner}
        footer={
          <div>
            <Button
              label="Save"
              icon="pi pi-check"
              onClick={saveNewPartner}
            />
            <Button
              label="Cancel"
              icon="pi pi-times"
              onClick={cancelAddPartner}
            />
          </div>
        }
      >
        
        <AWSS3Uploader
         onUploadComplete={(url) => setNewPartner({ ...newPartner, partnerImage: url })}
         
        />
        <div style={{ marginTop: "10px",display:'flex',alignItems:'center',width:'60%' }} className="p-inputgroup">
       
          <label htmlFor="partnerName">Partner Name:</label>
          <input
            type="text"
            id="partnerName"
            className="p-inputtext"
            value={newPartner.partnerName}
            onChange={(e) =>
              setNewPartner({ ...newPartner, partnerName: e.target.value })
            }
          />
        </div>
      </Dialog>
    </div>
  );
};

export default Partners;
