import React from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  TimePicker,
  InputNumber,
  Spin,
  notification,
  Tooltip
} from "antd";
import { editTicket, getcategory } from "../../requests";
import { LoadingOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";
import Testing from "../../containers/testing/index";
import AWSS3Uploader from "../s3uploader";

const { Option } = Select;

const TicketModal = ({ visible, onCancel, ticket }) => {
  // console.log(ticket);
  const [form] = Form.useForm();
  const [originalValues, setOriginalValues] = React.useState({});
  const id = ticket?._id;
  const [loading, setLoading] = useState(false);
  const [thumbnailPhoto, setThumbnailPhoto] = useState(null);
  const [ArtistPhoto, setartistphoto] = useState(null);
  const [eventPhoto, seteventphoto] = useState(null);
  const [allCategories, setallcategories] = useState([]);
  const [newDate, setNewDate] = useState(null);
  const [newTime, setNewTime] = useState(null);
  const [convFeeType, setConvFeeType] = useState("overall");
  const [seatData, setSeatdata] = useState("");
  React.useEffect(() => {
    form.setFieldsValue(ticket);
    setOriginalValues(ticket);
    setThumbnailPhoto(ticket?.thumbnailPhoto);
    setartistphoto(ticket?.ArtistPhoto);
    seteventphoto(ticket?.eventPhoto);
    setNewDate(null);
    setNewTime(null);
  }, [ticket, form]);

  

  const handleSeatData = (data) => {
    // console.log(data)
    setSeatdata(data);
  };

  const handleConvFeeTypeChange = (value) => {
    setConvFeeType(value);
  };

  

  const getChangedValues = (formData) => {
    const changedValues = {};
    Object.keys(formData).forEach((key) => {
      if (originalValues[key] !== formData[key]) {
        changedValues[key] = formData[key];
      }
    });
    return changedValues;
  };

  const updateTicket = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");

      const { Datee, timee, ...otherValues } = form.getFieldsValue();
      const isDateModified = newDate !== null;
      const isTimeModified = newTime !== null;

      const eventDate = isDateModified
        ? newDate.format("YYYY-MM-DD")
        : ticket?.eventDate;
      const time = isTimeModified ? newTime.format("h:mm a") : ticket?.time;

      const alldata = {
        ...otherValues,
        thumbnailPhoto,
        ArtistPhoto,
        eventPhoto,
        eventDate,
        time,
        seatArrangement: seatData,
      };

      if (!seatData) {
        notification.error({
          message: "Error",
          description: "Please Click the Save Seats button to continue",
        });
        return;
      }
      const resp = await editTicket(id, alldata, token);
      console.log(alldata);

      notification.success({
        message: "Ticket Updated",
        description: "The ticket has been successfully updated.",
      });

      console.log(resp);
      setSeatdata("")
      onCancel();
    } catch (err) {
      // Display error notification
      notification.error({
        message: "Error",
        description: "An error occurred while updating the ticket.",
      });

      console.log(err);
    } finally {
      setLoading(false);
     
    }
  };


  function convertToBase64(e) {
    console.log(e);
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      console.log(reader.result);
      setThumbnailPhoto(reader.result);
      // setimage(reader.result)
    };
    reader.onerror = (error) => {
      console.log("error", error);
    };
  }

  function convertToBase64Artist(e) {
    console.log(e);
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      console.log(reader.result);
      setartistphoto(reader.result);
      // setimage(reader.result)
    };
    reader.onerror = (error) => {
      console.log("error", error);
    };
  }

  function convertToBase64event(e) {
    console.log(e);
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      console.log(reader.result);
      seteventphoto(reader.result);
      // setimage(reader.result)
    };
    reader.onerror = (error) => {
      console.log("error", error);
    };
  }

  const handleDatePickerChange = (date) => {
    setNewDate(date);
  };

  const handleTimePickerChange = (time) => {
    setNewTime(time);
  };

  const getcategoryall = async () => {
    try {
      const resp = await getcategory();
      // setcategories(resp.data);
      setallcategories(resp.data);
      // dispatch(setCategories(resp.data));
      console.log(resp.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getcategoryall();
  }, []);

  const handleCancel=()=>{
    setSeatdata("")
    onCancel()
  }

  const handleUploadComplete = (uploadedUrl) => {
   
    console.log("Uploaded URL:", uploadedUrl);
    setThumbnailPhoto(uploadedUrl);
  };

  const handleUploadEventPhoto = (uploadedUrl) => {
  
    console.log("Uploaded URL:", uploadedUrl);
    seteventphoto(uploadedUrl);
  };

  const handleUploadArtist = (uploadedUrl) => {
   
    console.log("Uploaded URL:", uploadedUrl);
    setartistphoto(uploadedUrl);
  };

  return (
    <Modal
      title="Edit Ticket"
      visible={visible}
      onCancel={() => {
        onCancel();
        setSeatdata("")
        setOriginalValues({});
        form.resetFields();
      }}
      width={1200}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Tooltip title={!seatData ? "Please save seat data to continue" : ""}>
        <span style={{marginLeft:'10px'}}>
          <Button
            key="save"
            type="primary"
            onClick={updateTicket}
            disabled={!seatData}
          >
            Save
          </Button>
        </span>
      </Tooltip>,
      ]}
    >
      <div>
        <Form form={form} layout="vertical">
        <Form.Item
            name="eventCategory"
            label="Category"
            rules={[{ required: true, message: "Please select a category" }]}
          >
            <Select>
              {allCategories.map((category) => (
                <Option key={category.name} value={category.name}>
                  {category.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please enter a name" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: "Please enter description" }]}
          >
            <CKEditor
              editor={ClassicEditor}
              config={{
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "link",
                  "bulletedList",
                  "numberedList",
                  "blockQuote",
                  "undo",
                  "redo",
                ],
              }}
              data={ticket?.description}
              onChange={(event, editor) => {
                const data = editor.getData();
                form.setFieldsValue({ description: data });
              }}
            />
          </Form.Item>

          <Form.Item
            name="city"
            label="City"
            rules={[{ required: true, message: "Please enter city" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="venue"
            label="Venue"
            rules={[{ required: true, message: "Please enter a venue" }]}
          >
            <Input />
          </Form.Item>

         
          <Form.Item
            name="venueMapLocation"
            label="Venue Map Location"
            rules={[{ required: true, message: "Please enter map location" }]}
          >
            <Input />
          </Form.Item>
       
          <Form.Item
            name="navigationLink"
            label="Navigation Link"
            rules={[
              { required: true, message: "Please enter Navigation Link" },
            ]}
          >
            <Input />
          </Form.Item>
          

          <Form.Item
            name="Datee"
            label="Event Date"
            rules={[{ required: true, message: "Please select date" }]}
          >
            <p>
              Current Event Date:{" "}
              {moment(ticket?.eventDate).format("YYYY-MM-DD")}
            </p>
            <DatePicker onChange={handleDatePickerChange} />
          </Form.Item>

          <Form.Item
            name="timee"
            label="Time"
            rules={[{ required: true, message: "Please select time" }]}
          >
            <p>Current Event Time : {ticket?.time}</p>
            <TimePicker format="h:mm a" onChange={handleTimePickerChange} />
          </Form.Item>

         
          <Form.Item
            name="duration"
            label="Duration"
            rules={[{ required: true, message: "Please enter duration" }]}
          >
            <Input />
          </Form.Item>
         

          <Form.Item
            name="price"
            label="Starting Price"
            rules={[{ required: true, message: "Please enter price" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="numberOfSeats" label="Number of Seats">
            <Input />
          </Form.Item>

         

         

          <Form.Item name="artistName" label="Artist Name">
            <Input />
          </Form.Item>


         

          <Form.Item name="ArtistPhoto" label="Artist Photo">
            <p style={{ fontWeight: "600" }}>
              Maximum size of image to be less than 2mb
            </p>
            {/* <input
              accept="image/*"
              type="file"
              onChange={convertToBase64Artist}
            /> */}
              <AWSS3Uploader onUploadComplete={handleUploadArtist} />
            <br></br>
            {ArtistPhoto ? (
              <img width={100} height={100} src={ArtistPhoto} alt="Artist" />
            ) : (
              ""
            )}
          </Form.Item>

          <Form.Item name="thumbnailPhoto" label="Thumbnail Photo">
            <p style={{ fontWeight: "600" }}>
              Maximum size of image to be less than 2mb
            </p>
            {/* <input accept="image/*" type="file" onChange={convertToBase64} /> */}
            <AWSS3Uploader onUploadComplete={handleUploadComplete} />
            <br></br>
            {thumbnailPhoto === "" || thumbnailPhoto === null ? null : (
              <img width={100} height={100} src={thumbnailPhoto} />
            )}
          </Form.Item>

        

          <Form.Item name="eventPhoto" label="Event Photo">
            <p style={{ fontWeight: "600" }}>
              Maximum size of image to be less than 2mb
            </p>
            {/* <input
              accept="image/*"
              type="file"
              onChange={convertToBase64event}
            /> */}
              <AWSS3Uploader onUploadComplete={handleUploadEventPhoto} />
            <br></br>
            {eventPhoto === "" || eventPhoto === null ? null : (
              <img width={100} height={100} src={eventPhoto} />
            )}
          </Form.Item>

          {/* <Form.Item
          name="seatPrices.northGateFrontRow"
          label="North Gate Front Row Price"
          initialValue={ticket?.seatPrices?.northGateFrontRow}
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          name="seatPrices.northGateMiddleRow"
          label="North Gate Middle Row Price"
          initialValue={ticket?.seatPrices?.northGateMiddleRow}
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          name="seatPrices.southGateFrontRow"
          label="South Gate Front Row Price"
          initialValue={ticket?.seatPrices?.southGateFrontRow}
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          name="seatPrices.southGateMiddleRow"
          label="South Gate Middle Row Price"
          initialValue={ticket?.seatPrices?.southGateMiddleRow}
        >
          <InputNumber />
        </Form.Item> */}

          <Form.Item
            name="status"
            label="Status"
            rules={[{ required: true, message: "Please select a status" }]}
          >
            <Select>
              <Option value="draft">Draft</Option>
              <Option value="publish">Publish</Option>
            </Select>
          </Form.Item>
        </Form>
        <div style={{ height: "100%" }}>
          <h3>Seat Arrangement</h3>
          <Testing
            seatArrangement={ticket?.seatArrangement}
            savedSeatdata={handleSeatData}
          />
        </div>
      </div>
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />}
        spinning={loading}
        fullscreen
      />
    </Modal>
  );
};

export default TicketModal;
