import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import styles from './basic.module.css'


const AuthLayout = () => {
  const { Footer } = Layout;
  return (
    <Layout className={styles.authLayout}>
      <Outlet />
      <Footer className={styles.footer}>
        Powered By CreWork
      </Footer>
    </Layout>
  );
};

export default AuthLayout;
