import React, { useEffect, useState } from "react";
import { queries } from "../../requests";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button as Pribtn } from "primereact/button";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { saveAs } from "file-saver";

const Queries = () => {
  const [query, setQuery] = useState([]);

  const getAll = async () => {
    try {
      const resp = await queries();
      setQuery(resp.data.queries);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAll();
  }, []);

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);

        const exportColumns = [
          { title: "Name", dataKey: "name" },
          { title: "Email", dataKey: "email" },
          { title: "Query", dataKey: "query" },
          // Include additional columns if needed
        ];

        const dataForPdf = query.map((q) => ({ ...q }));

        doc.autoTable(exportColumns, dataForPdf);
        doc.save("queries.pdf");
      });
    });
  };

  const columns = [
    { field: "name", header: "Name" },
    { field: "email", header: "Email" },
    { field: "query", header: "Query" },
    // Include additional columns if needed
  ];

  return (
    <div>
      {/* <h1>Query List</h1> */}
      <div style={{ marginBottom: "10px" }}>
        <Pribtn
          type="button"
          label="Export PDF"
          icon="pi pi-file-pdf"
          rounded
          onClick={exportPdf}
        />
      </div>
      <DataTable
        value={query}
        paginator
        rows={10}
        dataKey="id"
        header="Queries List"
        emptyMessage="No queries found."
      >
        {columns.map((column, index) => (
          <Column
            key={index}
            field={column.field}
            header={column.header}
            body={column.body}
          />
        ))}
      </DataTable>
    </div>
  );
};

export default Queries;
