import { Form, Input, Button ,notification} from "antd";
import { login } from "../../requests";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./login.module.css";
import Logo from '../../assets/images/logonew.png'

const Login = () => {
  const tok = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    // const tok=localStorage.getItem('token')
    return tok ? navigate("/app") : navigate("/");
  }, [tok]);

  const onFinish = async (values) => {
    try {
      const resp = await login(values);
      console.log(resp.data);
      const organiserUID = resp.data.organiserUID;
      const organizerName = resp.data.organizerName;
      const token = resp.data.token;
      if (organiserUID) {
        sessionStorage.setItem("organizerUID", organiserUID);
      }

      if (organizerName) {
        localStorage.setItem("organizerName", organizerName);
      }
      localStorage.setItem("token", token);
      notification.success({
        message: 'Login Success',
        description: 'You have successfully logged in.',
      });
      return token ? navigate("/app") : navigate("/");
    } catch (err) {
      console.log(err);
      notification.error({
        message: 'Login Failed',
        description: 'There was an error during login. Please try again.',
      });
    }
  };

  return (
    <div className={styles.content}>
      <div className={styles.form}>
        <img src={Logo} alt="logo" className={styles.logo}/>
        <h2 style={{ color: "black" }}>Welcome</h2>
        <Form name="loginForm" onFinish={onFinish}>
          <Form.Item
            name="username"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input placeholder="Username" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
          <div className={styles.loginBtnDiv}>
          <Form.Item>
            <Button type="primary" htmlType="submit" className={styles.loginBtn}>
              Login
            </Button>
          </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};
export default Login;
