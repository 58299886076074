import React, { useEffect, useState } from "react";
import { Form, Input, InputNumber, Select, Button,message } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

const Testing = ({seatArrangement,savedSeatdata}) => {
  const [convFeeType, setConvFeeType] = useState("");
  const [form] = Form.useForm();

const onTesting = (values) => {
  const { seatarrangement, ...filteredValues} = values;

  // console.log("Form Data:", filteredValues);
  savedSeatdata(filteredValues)
  message.success('Seats updated successfully');
};

  const handleConvFeeTypeChange = (value) => {
    setConvFeeType(value);
  };

  useEffect(() => {
    setConvFeeType(seatArrangement?.convFeeType);
  
    if (seatArrangement?.seatPrices) {
      const seatPricesValues = seatArrangement.seatPrices.map((seatPrice, index) => ({
        label: seatPrice.label || "",
        price: seatPrice.price || 0,
        totalSeats:seatPrice.totalSeats || 0,
        mode: seatPrice.mode || "",
        convFee: seatPrice.convFee || 0,
        _id: seatPrice._id || `tempId-${index}`, // Use a temporary ID for new items
      }));
  
      form.setFieldsValue({
        seatPrices: seatPricesValues,
        convFee: seatArrangement?.convFee || 0, 
        convFeeType: seatArrangement?.convFeeType, 
        mode: seatArrangement?.mode || "false",
      });
    }
  }, [seatArrangement, form]);



  return (
    <div style={{height:'100%'}}>
      <Form form={form} onFinish={onTesting}>
        <Form.Item name="seatarrangement">
          <Form.Item name="convFeeType" label="Conv Fee Type">
            <Select  onChange={handleConvFeeTypeChange}  defaultValue={seatArrangement?.convFeeType}>
              <Option value="overall">Overall</Option>
              <Option value="perticket">Per Ticket</Option>
            </Select>
          </Form.Item>

          {convFeeType=== "overall" && (
            <>
              <Form.Item name="mode" label="Mode">
                <Select defaultValue={seatArrangement?.mode}>
                  <Option value="percentage">Percentage</Option>
                  <Option value="fixed">Fixed</Option>
                </Select>
              </Form.Item>

              <Form.Item name="convFee" label="Conv Fee">
                <InputNumber defaultValue={seatArrangement?.convFee}/>
              </Form.Item>
            </>
          )}

          <Form.List name="seatPrices">
            {(fields, { add, remove }) => (
              <div>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <div key={key} style={{ display: "flex", marginBottom: 8 }}>
                    <Form.Item
                      {...restField}
                      name={[name, "label"]}
                      fieldKey={[fieldKey, "label"]}
                      rules={[
                        { required: true, message: "Please enter the label" },
                      ]}
                      label="Label"
                      style={{ width: "100%" }}
                    >
                      <Input placeholder="Label" />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, "price"]}
                      fieldKey={[fieldKey, "price"]}
                      rules={[
                        { required: true, message: "Please enter the price" },
                      ]}
                      label="Price"
                      style={{ width: "100%" }}
                    >
                      <InputNumber placeholder="Price" />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, "totalSeats"]}
                      fieldKey={[fieldKey, "totalSeats"]}
                      rules={[
                        {
                          required: true,
                          message: "Please enter the total seats",
                        },
                      ]}
                      label="Total Seats"
                      style={{ width: "100%" }}
                    >
                      <InputNumber placeholder="Total Seats" />
                    </Form.Item>

                    {convFeeType === "perticket" && (
                      <>
                        <Form.Item
                          {...restField}
                          name={[name, "mode"]}
                          fieldKey={[fieldKey, "mode"]}
                          rules={[
                            {
                              required: true,
                              message: "Please select the mode",
                            },
                          ]}
                          label="Mode"
                          style={{ width: "100%" }}
                        >
                          <Select placeholder="Mode">
                            <Option value="percentage">Percentage</Option>
                            <Option value="fixed">Fixed</Option>
                          </Select>
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, "convFee"]}
                          fieldKey={[fieldKey, "convFee"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter the conv fee",
                            },
                          ]}
                          label="Conv Fee"
                          style={{ width: "100%" }}
                        >
                          <InputNumber placeholder="Conv Fee" />
                        </Form.Item>
                      </>
                    )}

                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </div>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add Row
                  </Button>
                </Form.Item>
              </div>
            )}
          </Form.List>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save Seats
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Testing;
