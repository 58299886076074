import React, { useState, useEffect } from "react";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

import {
  Form,
  Input,
  Select,
  DatePicker,
  Upload,
  Button,
  Spin,
  TimePicker,
  notification,
  InputNumber,
} from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { LoadingOutlined } from "@ant-design/icons";
import { createTicket, getcategory, getorganizers } from "../../requests";
import AWSS3Uploader from "../../components/s3uploader";
import { Buffer } from "buffer";
Buffer.from("anything", "base64");

const { Option } = Select;

const AddTicket = () => {
  const [form] = Form.useForm();
  const [Image, setimage] = useState("");
  const [loading, setLoading] = useState(false);
  const [thumbnailPhoto, setThumbnailPhoto] = useState(null);
  const [ArtistPhoto, setartistphoto] = useState(null);
  const [eventPhoto, seteventphoto] = useState(null);
  const [organizers, setorganizers] = useState([]);
  const [convFeeType, setConvFeeType] = useState("overall");

  const handleConvFeeTypeChange = (value) => {
    setConvFeeType(value);
  };

  const [allCategories, setallcategories] = useState([]);

  const onFinish = async (values) => {
    try {
      setLoading(true);

      const { Datee, timee, ...otherValues } = values;
      const eventDate = Datee.format("YYYY-MM-DD");
      const time = timee.format("h:mm a");

      // const seatArrangement = {
      //   convFeeType: values.convFeeType,
      //   mode: values.mode,
      //   convFee: values.convFee,
      //   seatPrices: values.seatPricesValue.map((seat) => ({
      //     label: seat.label,
      //     price: seat.price,
      //     mode: seat.mode,
      //     convFee: seat.convFee,
      //   })),
      // };

      const formData = {
        ...otherValues,
        thumbnailPhoto,
        ArtistPhoto,
        eventPhoto,
        eventDate,
        time,
        seatArrangement: {
          convFeeType: values.convFeeType,
          mode: values.mode,
          convFee: values.convFee,
          seatPrices: values.seatPricesValue.map((seat) => ({
            label: seat.label,
            price: seat.price,
            totalSeats: seat.totalSeats,
            mode: seat.mode,
            convFee: seat.convFee,
          })),
        },
      };

      const organizerName = localStorage.getItem("organizerName");

      if (organizerName) {
        formData.organizerName = organizerName;
      }

      console.log("Form values:", formData);

      const token = localStorage.getItem("token");

      const response = await createTicket(token, formData);

      console.log("Response from createTicket:", response.data);
      notification.success({
        message: "Ticket Created",
        description: "The ticket has been created successfully.",
      });
      form.resetFields();
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Ticket Creation Failed",
        description: "Failed to create the ticket. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  function convertToBase64(e) {
    console.log(e);
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      console.log(reader.result);
      setThumbnailPhoto(reader.result);
      // setimage(reader.result)
    };
    reader.onerror = (error) => {
      console.log("error", error);
    };
  }

  function convertToBase64Artist(e) {
    console.log(e);
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      console.log(reader.result);
      setartistphoto(reader.result);
      // setimage(reader.result)
    };
    reader.onerror = (error) => {
      console.log("error", error);
    };
  }

  function convertToBase64event(e) {
    console.log(e);
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      console.log(reader.result);
      seteventphoto(reader.result);
      // setimage(reader.result)
    };
    reader.onerror = (error) => {
      console.log("error", error);
    };
  }

  const isCategoryToHide = [
    "Festivities",
    "adventure",
    "Food",
    "sports",
  ].includes(form.getFieldValue("eventCategory"));

  const CustomNameValidator = (_, value) => {
    if (value && value.charAt(0) !== value.charAt(0).toUpperCase()) {
      return Promise.reject(new Error("Name must start with a capital letter"));
    }

    return Promise.resolve();
  };

  const getcategoryall = async () => {
    try {
      const resp = await getcategory();
      // setcategories(resp.data);
      setallcategories(resp.data);
      // dispatch(setCategories(resp.data));
      console.log(resp.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getcategoryall();
    getorg();
  }, []);

  const getorg = async () => {
    try {
      const resp = await getorganizers();
      console.log(resp.data);
      setorganizers(resp.data);
    } catch (err) {
      console.log(err);
    }
  };
  const organizerNameInLocalStorage = localStorage.getItem("organizerName");

  const onTesting = (values) => {
    console.log("onTesting function called");
    console.log("Form Data:", values);

    return false;
  };

  const handleUploadComplete = (uploadedUrl) => {
   
    console.log("Uploaded URL:", uploadedUrl);
    setThumbnailPhoto(uploadedUrl);
  };

  const handleUploadEventPhoto = (uploadedUrl) => {
  
    console.log("Uploaded URL:", uploadedUrl);
    seteventphoto(uploadedUrl);
  };

  const handleUploadArtist = (uploadedUrl) => {
   
    console.log("Uploaded URL:", uploadedUrl);
    setartistphoto(uploadedUrl);
  };

  return (
    <div>
      <h1 style={{ textAlign: "center" }}>Add Ticket</h1>
      <Form
        name="addTicketsForm"
        onFinish={onFinish}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        form={form}
      >

<Form.Item
          name="eventCategory"
          label="Category"
          rules={[{ required: true, message: "Please select a category" }]}
        >
          <Select>
            {allCategories.map((category) => (
              <Option key={category.name} value={category.name}>
                {category.name}
              </Option>
            ))}
          </Select>
        </Form.Item>


        {organizerNameInLocalStorage ? null : (
          <Form.Item
            name="organizerName"
            label="Organizer Name"
            rules={[{ required: true, message: "Please select an organizer" }]}
          >
            <Select>
              {organizers.map((organizer) => (
                <Option key={organizer._id} value={organizer.organizerName}>
                  {organizer.organizerName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}



        <Form.Item
          name="name"
          label="Name"
          rules={[
            { required: true, message: "Please enter a name" },
            { validator: CustomNameValidator },
          ]}
        >
          <Input />
        </Form.Item>

       

       

      

        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: "Please enter description" }]}
        >
          <CKEditor
            editor={ClassicEditor}
            config={{
              toolbar: [
                "heading",
                "|",
                "bold",
                "italic",
                "link",
                "bulletedList",
                "numberedList",
                "blockQuote",
                "undo",
                "redo",
              ],
            }}
            data={""}
            onChange={(event, editor) => {
              const data = editor.getData();
              form.setFieldsValue({ description: data });
            }}
          />
        </Form.Item>

        <Form.Item
          name="city"
          label="City"
          rules={[{ required: true, message: "Please enter city" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="venue"
          label="Venue"
          rules={[{ required: true, message: "Please enter a venue" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="venueMapLocation"
          label="Venue Map Location"
          rules={[{ required: true, message: "Please enter map location" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="navigationLink"
          label="Navigation Link"
          rules={[{ required: true, message: "Please enter map location" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="Datee"
          label="Event Date"
          rules={[{ required: true, message: "Please select date" }]}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item
          name="timee"
          label="Time"
          rules={[{ required: true, message: "Please select time" }]}
        >
          <TimePicker format="h:mm a" />
        </Form.Item>

        <Form.Item
          name="duration"
          label="Duration"
          rules={[{ required: true, message: "Please enter duration" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="price"
          label="Price"
          rules={[{ required: true, message: "Please enter price" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="numberOfSeats" label="Number of Seats">
          <Input />
        </Form.Item>

      

        

        <Form.Item
          name="artistName"
          label="Artist Name"
          // hidden={isCategoryToHide}
        >
          <Input />
        </Form.Item>

        <Form.Item name="ArtistPhoto" label="Artist Photo">
          <p style={{ fontWeight: "600" }}>
            Maximum size of image to be less than 2mb
          </p>
          {/* <input
            accept="image/*"
            type="file"
            onChange={convertToBase64Artist}
          /> */}
           <AWSS3Uploader onUploadComplete={handleUploadArtist} />
          <br></br>
          {ArtistPhoto == "" || ArtistPhoto == null ? (
            ""
          ) : (
            <img width={100} height={100} src={ArtistPhoto} />
          )}
        </Form.Item>

        <Form.Item name="thumbnailPhoto" label="Thumbnail Photo">
          <p style={{ fontWeight: "600" }}>
            Maximum size of image to be less than 2mb
          </p>
          {/* <input accept="image/*" type="file" onChange={convertToBase64} /> */}
          <AWSS3Uploader onUploadComplete={handleUploadComplete} />
          <br></br>
          {thumbnailPhoto === "" || thumbnailPhoto === null ? null : (
            <img width={100} height={100} src={thumbnailPhoto} />
          )}
        </Form.Item>

        <Form.Item name="eventPhoto" label="Event Photo">
          <p style={{ fontWeight: "600" }}>
            Maximum size of image to be less than 2mb
          </p>
          {/* <input accept="image/*" type="file" onChange={convertToBase64event} /> */}
          <AWSS3Uploader onUploadComplete={handleUploadEventPhoto} />
          <br></br>
          {eventPhoto === "" || eventPhoto === null ? null : (
            <img width={100} height={100} src={eventPhoto} />
          )}
        </Form.Item>

        <div style={{ background: "#fff", padding: "20px" }}>
          {/* <Form form={form} onFinish={onTesting}> */}
          <Form.Item name="convFeeType" label="Conv Fee Type">
            <Select onChange={handleConvFeeTypeChange}>
              <Option value="overall">Overall</Option>
              <Option value="perticket">Per Ticket</Option>
            </Select>
          </Form.Item>

          {convFeeType === "overall" && (
            <>
              <Form.Item name="mode" label="Mode">
                <Select>
                  <Option value="percentage">Percentage</Option>
                  <Option value="fixed">Fixed</Option>
                </Select>
              </Form.Item>

              <Form.Item name="convFee" label="Conv Fee">
                <InputNumber />
              </Form.Item>
            </>
          )}

          <Form.List name="seatPricesValue">
            {(fields, { add, remove }) => (
              <div>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <div key={key} style={{ display: "flex", marginBottom: 8 }}>
                    <Form.Item
                      {...restField}
                      name={[name, "label"]}
                      fieldKey={[fieldKey, "label"]}
                      rules={[
                        { required: true, message: "Please enter the label" },
                      ]}
                      label="Label"
                      style={{ width: "100%" }}
                    >
                      <Input placeholder="Label" />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, "price"]}
                      fieldKey={[fieldKey, "price"]}
                      rules={[
                        { required: true, message: "Please enter the price" },
                      ]}
                      label="Price"
                      style={{ width: "100%" }}
                    >
                      <InputNumber placeholder="Price" />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, "totalSeats"]}
                      fieldKey={[fieldKey, "totalSeats"]}
                      rules={[
                        {
                          required: true,
                          message: "Please enter the total seats",
                        },
                      ]}
                      label="Total Seats"
                      style={{ width: "100%" }}
                    >
                      <InputNumber placeholder="Total Seats" />
                    </Form.Item>

                    {form.getFieldValue(["convFeeType"]) === "perticket" && (
                      <>
                        <Form.Item
                          {...restField}
                          name={[name, "mode"]}
                          fieldKey={[fieldKey, "mode"]}
                          rules={[
                            {
                              required: true,
                              message: "Please select the mode",
                            },
                          ]}
                          label="Mode"
                          style={{ width: "100%" }}
                        >
                          <Select placeholder="Mode">
                            <Option value="percentage">Percentage</Option>
                            <Option value="fixed">Fixed</Option>
                          </Select>
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, "convFee"]}
                          fieldKey={[fieldKey, "convFee"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter the conv fee",
                            },
                          ]}
                          label="Conv Fee"
                          style={{ width: "100%" }}
                        >
                          <InputNumber placeholder="Conv Fee" />
                        </Form.Item>
                      </>
                    )}

                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </div>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add Row
                  </Button>
                </Form.Item>
              </div>
            )}
          </Form.List>

          {/* </Form> */}
          {/* <Form.Item
          name="seatPrices.northGateFrontRow"
          label="North Gate Front Row Price"
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          name="seatPrices.northGateMiddleRow"
          label="North Gate Middle Row Price"
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          name="seatPrices.southGateFrontRow"
          label="South Gate Front Row Price"
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          name="seatPrices.southGateMiddleRow"
          label="South Gate Middle Row Price"
        >
          <InputNumber />
        </Form.Item> */}
        </div>

      
        <Form.Item
          name="status"
          label="Status"
          rules={[{ required: true, message: "Please select a status" }]}
        >
          <Select>
            <Option value="draft">Draft</Option>
            <Option value="publish">Publish</Option>
          </Select>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 4, span: 14 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />}
        spinning={loading}
        fullscreen
      />
    </div>
  );
};

export default AddTicket;
