import ManageTickets from "../containers/managetickets"; // Import your ManageTickets component
import AuthLayout from "../layouts/AuthLayout";
import BasicLayout from "../layouts/BasicLayout";
import Home from "../containers/home";
import Login from "../containers/Login";
import Users from "../containers/Users";
import AddTicket from "../containers/addticket";
import Bookings from "../containers/bookings";
import Resetpass from "../containers/resetpassword";
import Protected from "./Protected";
import AddCity from "../containers/addcity";
import AddCategory from "../containers/addCategory";
import Partners from "../containers/partners";
import Queries from "../containers/queries";
import Listyourevents from "../containers/listyourevents";
import ListAdmins from "../containers/listAdmins";
import AddAdmin from "../containers/add admin";
import Testing from "../containers/testing";
import Career from "../containers/career";
import { Route, Routes, useNavigate } from "react-router-dom";

const Rout = () => {
  return (
    <Routes>
      <Route path="/" element={<AuthLayout />}>
        <Route index element={<Login />} />
      </Route>

      <Route path="/app" element={<Protected Component={BasicLayout} />}>
        <Route index element={<Home />} />
        <Route path="tickets" element={<ManageTickets />} />
        <Route path="users" element={<Users />} />
        <Route path="addticket" element={<AddTicket />} />
        <Route path="bookings" element={<Bookings />} />
        <Route path="resetpass" element={<Resetpass />} />
        <Route path="addcity" element={<AddCity />} />
        <Route path="addcategory" element={<AddCategory />} />
        <Route path="partners" element={<Partners />} />
        <Route path="queries" element={<Queries />} />
        <Route path="listyourevents" element={<Listyourevents />} />
        <Route path="listadmins" element={<ListAdmins />} />
        <Route path="addadmin" element={<AddAdmin />} />
        <Route path="career" element={<Career />} />
      </Route>
    </Routes>
  );
};

export default Rout;
